import { Link as MuiLink, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import OnboardingPage from 'components/OnboardingPage';
import ReferralCodeValidateBanner from 'components/ReferralCodeValidateBanner';
import SignUpForm from 'components/SignUpForm';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import * as impact from 'helpers/impact';
import { trackProductAnalytics } from 'libs/productAnalyticsTracking';

const SignUpPage: React.FC = () => {
  const { currentUser } = useContext(AuthContext); // emailVerified comes from here

  useEffect(() => {
    impact.identify();
  }, [currentUser]);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>Sign Up for Kovo Credit Builder</title>
        <meta
          name="description"
          content="Sign up to start with Kovo Credit Builder. Apply and get approved in less than 2 minutes. No credit check."
        />
      </Helmet>

      <OnboardingPage linkLogoToHomepage supportSubject="signup">
        <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
          Start building credit!
        </Typography>

        <ReferralCodeValidateBanner />

        <Typography marginBottom={theme.spacing(3)}>
          No credit check. No hidden fees. Instant decision. All 4 bureaus.
        </Typography>

        <SignUpForm />

        <Typography marginTop={theme.spacing(6)} textAlign="center">
          Already have an account?{' '}
          <MuiLink
            onClick={() => {
              trackProductAnalytics({
                namespace: 'ui',
                event: 'login.clicked',
              });
            }}
            component={Link}
            to="/login"
          >
            Log in
          </MuiLink>
          .
        </Typography>
      </OnboardingPage>
    </>
  );
};

export default SignUpPage;
