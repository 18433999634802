import { Auth, CognitoUser } from '@aws-amplify/auth';
import { HubCallback } from '@aws-amplify/core';
import { Hub } from 'aws-amplify';
import { createContext, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { getCognitoUserAuthDetails } from 'libs/signupHelpers/getCognitoUserAuthDetails';
import posthog from 'posthog-js';

export type PreConfirmationState = {
  email: string;
  password: string;
};

// logged in:     currentUser === CurrentUser
// logged out:    currentUser === null
// uninitialized: currentUser === undefined
export type State = {
  currentUser: CognitoUser | null | undefined;
  initialized: boolean;
  email: string;
  emailVerified: boolean;
  identityId: string;
  identityToken: string | null;
  username: string; // backwards compat, use identityId if you can
  /**
   * Instead of using router state which could be wiped clean with another navigation,
   * store the email/password here temporarily until the user has confirmed their
   * email address as a part of the initial onboarding steps.
   */
  preConfirmationState?: PreConfirmationState;
  /**
   * Signal to set when the login or signup pages are calling authChangeCallback
   * but do not want the app to automatically re-render to the homepage.
   *
   * Set to true to block that re-render, set to false to allow it.
   */
  logInCompleting?: boolean;
  pendingEmail?: string;
  pendingEmailTimestamp?: string;
  authChangeCallback(
    currentUser: CognitoUser | null,
    authDetails: UserAuthDetails | null,
    preConfirmationState?: PreConfirmationState,
    logInCompleting?: boolean,
  ): Promise<void>;
  refresh(): Promise<void>;
  clearLogInCompleteToken(): void;
};

export type CognitoUserAttribute = {
  Name: string;
  Value: string;
};

// from aws-cognito-identity-js
export type ISignUpResult = {
  user: CognitoUser;
  userConfirmed: boolean;
  userSub: string;
};

export type UserAuthDetails = {
  identityId: string;
  email: string;
  emailVerified: boolean;
  username: string;
  pendingEmail?: string;
  pendingEmailTimestamp?: string; // ISO string
};

export const AuthContext = createContext<State>({
  currentUser: undefined,
  initialized: false,
  email: '',
  emailVerified: false,
  identityId: '',
  username: '',
  identityToken: null,
  authChangeCallback: async () => {},
  refresh: async () => {},
  clearLogInCompleteToken: () => {},
});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

interface Props {
  children: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const queryClient = useQueryClient();

  /**
   * Called by the page that is navigated to after sign up completes.
   */
  const clearLogInCompleteToken = () => {
    setState((state) => {
      return {
        ...state,
        logInCompleting: false,
      };
    });
  };

  /**
   * Called when a login page handles login, or when Hub registers a 'signOut' event
   */
  const authChangeCallback = async (
    currentUser: CognitoUser | null,
    authDetails: UserAuthDetails | null,
    preConfirmationState?: PreConfirmationState,
    logInCompleting?: boolean,
  ) => {
    if (!currentUser && !authDetails && preConfirmationState) {
      setState((state) => {
        return {
          ...state,
          currentUser: null,
          preConfirmationState,
          logInCompleting: !!logInCompleting,
        };
      });

      return;
    }

    if (currentUser && authDetails) {
      setState({
        ...state,
        ...authDetails,
        currentUser,
        preConfirmationState,
        logInCompleting: !!logInCompleting,
        initialized: true,
        identityToken:
          currentUser.getSignInUserSession()?.getIdToken?.().getJwtToken?.() ??
          null,
      });

      /**
       * Set user id in GA when value exist
       * Trigger a custom event to make sure user id link activated
       * This could mean high hit rate for this event but it's not concerning at this moment
       */
      if (authDetails.identityId) {
        window.gtag('set', { user_id: authDetails.identityId });
        window.gtag('event', 'SetUserId');
        posthog.identify(authDetails.identityId, {
          kovoUserId: authDetails.identityId,
        });
      }

      if (rollbarEnabled) {
        // rollbar only allows 40 characters for the id
        // the identity ID from cognito is region:uuid, which is too long
        // uuid only is 36 char, so it is fine
        const id = authDetails.identityId.split(':')[1];
        rollbar.configure({
          payload: {
            person: {
              id,
            },
          },
        });
      }

      return;
    }

    // if the user logs out, invalidate all queries
    queryClient.removeQueries();

    setState({
      ...state,
      initialized: true,
      identityId: '',
      email: '',
      emailVerified: false,
      username: '',
      currentUser: null,
      preConfirmationState: undefined,
      logInCompleting: false,
    });
  };

  const refresh = async () => {
    initAuthStateData();
  };

  const initAuthStateData = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const authDetails = await getCognitoUserAuthDetails(user);

      authChangeCallback(user, authDetails);
    } catch (error) {
      if (error === 'The user is not authenticated') {
        authChangeCallback(null, null);
      } else {
        throw error;
      }
    }
  };

  const [state, setState] = useState<State>({
    currentUser: undefined,
    initialized: false,
    email: '',
    emailVerified: false,
    identityId: '',
    identityToken: null,
    username: '',
    logInCompleting: false,
    authChangeCallback,
    refresh,
    clearLogInCompleteToken,
  });

  const handleAuthEvent: HubCallback = async (data) => {
    switch (data.payload.event) {
      // we only handle the 'signOut' event here to support expiring a session
      // for signIn we explicitly call AuthContext.authChangeCallback() to avoid a race condition
      // see: https://github.com/kovoteam/web-app/pull/116#discussion_r817187303
      case 'signOut':
        authChangeCallback(null, null);

        // https://docs.rollbar.com/docs/javascript
        if (rollbarEnabled) {
          rollbar.configure({
            payload: {
              person: {
                id: null as any,
              },
            },
          });
        }

        break;
      // When the token refreshes, we need to update state so anything depending
      // on the identityToken will get the new value.
      case 'tokenRefresh':
        /**
         * Get the user info from Cognito, it is not a part of the event
         */
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const authDetails = await getCognitoUserAuthDetails(
          cognitoUser as CognitoUser,
        );
        authChangeCallback(cognitoUser, authDetails);
        break;
    }
  };

  useEffect(() => {
    const cancelListerer = Hub.listen('auth', handleAuthEvent);
    initAuthStateData();

    return () => cancelListerer();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Always wait for Cognito to do its initialization and first auth check before
   * running the page router.
   *
   * TODO: would it make sense to have some sort of initial page loader here?
   * TODO: should we have a timeout in the App.tsx component to show an error page
   *       if for some reason this never initializes?
   */

  if (!state.initialized) {
    return null;
  }

  /**
   * Now that we have some sort of initial auth state (either logged in or logged
   * out), allow the router and the rest of the app to render.
   */

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
