import { Typography } from '@mui/material';
import { Redirect, useHistory } from 'react-router-dom';

import OnboardingPage from 'components/OnboardingPage/OnboardingPage';
import PageLoader from 'components/PageLoader';
import PhoneNumberVerificationForm from 'components/PhoneNumberVerificationForm';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';

const PhoneNumberVerificationPage: React.FC = () => {
  const history = useHistory();

  const { data: applicationData, isLoading } = useApplicationStatus();

  const goToNextPage = () => {
    history.replace('/apply');
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (!applicationData) {
    return null;
  }

  if (!['new', 'detailsNeeded'].includes(applicationData.applicationStatus)) {
    return <Redirect to="/" />;
  }

  if (!!applicationData.applicationDetails?.phoneNumber) {
    return <Redirect to="/apply" />;
  }

  return (
    <OnboardingPage onboardingStep={2} supportSubject="verify-phone-number">
      <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
        Verify your number
      </Typography>

      <Typography marginBottom={theme.spacing(3)}>
        Check your phone for a verification code.
      </Typography>

      <PhoneNumberVerificationForm onSuccess={goToNextPage} />
    </OnboardingPage>
  );
};

export default PhoneNumberVerificationPage;
