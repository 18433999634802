import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';

import AutoLoanRefiIcon from 'assets/img/icons/offers/auto-loan-refi.svg';
import CreditCardIcon from 'assets/img/icons/offers/credit-card.svg';
import InsuranceIcon from 'assets/img/icons/offers/insurance.svg';
import PersonalLoanIcon from 'assets/img/icons/offers/personal-loan.svg';
import ServicesIcon from 'assets/img/icons/offers/services.svg';
import StudentLoanRefiIcon from 'assets/img/icons/offers/student-loan-refi.svg';
import StudentLoanIcon from 'assets/img/icons/offers/student-loan.svg';
import { theme } from 'context/ThemeProvider';

interface OfferRewardCategory {
  id: string;
  name: string;
}

export const iconMapping: { [key: string]: string } = {
  'Credit Cards': CreditCardIcon,
  'Auto Loan Refinancing': AutoLoanRefiIcon,
  'Student Loans': StudentLoanIcon,
  'Student Loan Refinancing': StudentLoanRefiIcon,
  'Personal Loans': PersonalLoanIcon,
  Insurance: InsuranceIcon,
  Services: ServicesIcon,
};

const displayOrder = [
  'Credit Cards',
  'Auto Loan Refinancing',
  'Student Loans',
  'Student Loan Refinancing',
  'Personal Loans',
  'Insurance',
  'Services',
];

const OfferCategoriesList: React.FC = () => {
  const { identityId, username } = useContext(AuthContext);

  const getOffersRewardsCategories = async () => {
    const categories = await API.get(
      'billing',
      '/billing/offer-categories',
      amplifyRequestContext(identityId, username),
    );

    return categories;
  };

  const { data: categories, isLoading } = useQuery<OfferRewardCategory[]>(
    ['categories'],
    () => getOffersRewardsCategories(),
  );

  if (isLoading) {
    return <PageLoader />;
  }

  if (categories?.length === 0) {
    return (
      <Card variant="gradient">
        <CardContent>
          <Typography>
            Offers are currently unavailable in your location, but this may
            change at a later time. We apologize for the inconvenience.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const sortedCategories = displayOrder
    .map((categoryName) => {
      return (
        categories?.find((category) => category.name === categoryName) || null
      );
    })
    .filter((category) => !!category) as OfferRewardCategory[];

  return (
    <Grid container spacing={2}>
      {sortedCategories?.map(({ id, name }) => (
        <Grid item xs={12} key={id}>
          <Card elevation={4}>
            <CardActionArea
              component={Link}
              to={`/offer-categories/${id}/offers`}
              disableRipple
            >
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  component="img"
                  src={iconMapping[name]}
                  alt=""
                  sx={{
                    width: '66px',
                    marginRight: theme.spacing(2),
                  }}
                />

                <Typography variant="h3">{name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default OfferCategoriesList;
