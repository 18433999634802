import { sendMessageToMobileApp } from 'helpers/mobile-app';
import posthog, { Properties } from 'posthog-js';
import getCurrentPlatform from './signupHelpers/getCurrentPlatform';

const USER_ANALYTICS_EVENTS = [
  'email.submission.succeeded',
  'email.verification.succeeded',
  'phone.verification.succeeded',
  'login.submission.succeeded',
] as const;
type UserAnalyticsEvent = typeof USER_ANALYTICS_EVENTS[number];

const APPLICATION_ANALYTICS_EVENTS = [
  'esign.signed',
  'submission.succeeded',
  'upfrontpayment.succeeded',
] as const;
type ApplicationAnalyticsEvent = typeof APPLICATION_ANALYTICS_EVENTS[number];
type ApplicationAttributes = {
  accountType: 'installment' | 'credit_line';
  monthlyPaymentAmountCents: 1000;
  termLength: 24 | null;
};

const PURCHASE_ANALYTICS_EVENTS = ['submission.succeeded'] as const;
type PurchaseAnalyticsEvent = typeof PURCHASE_ANALYTICS_EVENTS[number];
// service_1: course bundle 1 and ID monitoring
// service_2: course bundle 2 and IDT resolution/insurance
type PurchaseAttributes = {
  productId: 'service_1' | 'service_2';
};

const UI_ANALYTICS_EVENTS = [
  'forgot-password.clicked',
  'forgot-email.clicked',
  'signup.clicked',
  'signup.privacy-policy.clicked',
  'signup.terms-of-use.clicked',
  'signup.e-sign-consent.clicked',
  'signup.terms-and-conditions.checked',
  'resend-code.clicked',
  'signup.verify-phone-number-go-back.clicked',
  'apply.ssn-disclosure.checked',
  'apply.upfront-payment.clicked',
  'apply.invalid-address.shown',
  'apply.recommended-address.shown',
  'esign.reviewed',
  'product-benefits-table.view-more.clicked',
  'footer.get-in-touch.clicked',
  'referral.share-link.clicked',
  'referral.maybe-later.clicked',
  'app-download.maybe-later.clicked',
  'login.clicked',
  'logout.clicked',
] as const;
type UIAnalyticsEvent = typeof UI_ANALYTICS_EVENTS[number];

type CommonAnalyticsParams = {
  properties?: Properties | null;
  /**
   * If true, the event will be sent as a conversion event to the mobile app.
   * The event will then be sent to Singular.
   * There is an environment check on the mobile app to ensure the event is only
   * sent in production.
   *
   * @default false
   * @type {boolean}
   */
  sendAsConversionEventToMobileApp?: boolean;
  attributes?: Object;
};

export type TrackProductAnalyticsParams =
  | (CommonAnalyticsParams & {
      namespace: 'user';
      event: UserAnalyticsEvent;
    })
  | (CommonAnalyticsParams & {
      namespace: 'application';
      event: ApplicationAnalyticsEvent;
      attributes: ApplicationAttributes;
    })
  | (CommonAnalyticsParams & {
      namespace: 'purchase';
      event: PurchaseAnalyticsEvent;
      attributes: PurchaseAttributes;
    })
  | (CommonAnalyticsParams & {
      namespace: 'ui';
      event: UIAnalyticsEvent;
    });

export const trackProductAnalytics = ({
  namespace,
  event,
  attributes,
  properties,
  sendAsConversionEventToMobileApp,
}: TrackProductAnalyticsParams) => {
  const platform = getCurrentPlatform();
  if (attributes) {
    // Merge attributes into properties
    properties = {
      ...properties,
      ...attributes,
    };
  }
  // Log the event to the console for debugging (only in development)
  if (import.meta.env.DEV) {
    console.log(`[🦔 POSTHOG TRACKING]\nweb.${namespace}.${event}`, {
      ...properties,
      platform,
      currentPath: window.location.pathname,
    });
  }
  posthog.capture(`web.${namespace}.${event}`, {
    ...properties,
    platform,
    currentPath: window.location.pathname,
  });
  if (sendAsConversionEventToMobileApp) {
    sendMessageToMobileApp({
      eventType: 'kovo.webapp.track_conversion_event',
      body: {
        eventName: `${namespace}.${event}`,
        attributes: attributes ?? {},
      },
    });
  }
};
