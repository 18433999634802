import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import moment from 'moment';

import { PaymentMethodLabel } from 'components/PaymentMethodLabel';
import PopupDialog from 'components/PopupDialog';
import TransactionAmount from 'components/TransactionAmount';
import { theme } from 'context/ThemeProvider';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import useGetApplications from 'hooks/queries/useGetApplications';
import useGetTransactions from 'hooks/queries/useGetTransactions';
import TransactionInvoice, { InvoiceDescription } from '../TransactionInvoice';

const ChargeAccountTransactionsCard: React.FC = () => {
  const { data: accountsData, isLoading: isLoadingAccounts } = useGetAccounts();

  const { data: transactionsData, isLoading: isLoadingTransactions } =
    useGetTransactions(accountsData?.chargeAccount?.id);

  const { data: applicationsData, isLoading: isLoadingApplications } =
    useGetApplications();

  if (isLoadingAccounts || isLoadingTransactions || isLoadingApplications) {
    return <Skeleton variant="rounded" height={249} />;
  }

  if (!accountsData || !transactionsData || !applicationsData) {
    return null;
  }

  const { chargeAccountApplication } = applicationsData;

  if (!chargeAccountApplication) {
    return null;
  }

  /**
   * Only display successful transactions in the list (current practice). Showing that a transaction is processing is
   * handled by the top section on the page at this time because we update the account status to processing while a
   * payment is being attempted.
   */

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h5">TRANSACTION HISTORY</Typography>

        <Divider sx={{ margin: theme.spacing(2, 0) }} />
      </CardContent>

      <List
        disablePadding
        sx={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        {transactionsData.transactions
          .filter(
            (transaction) =>
              transaction.trigger !== 'principal' &&
              ['succeeded', 'pre_authorized'].includes(transaction.status),
          )
          .map((transaction) => ({
            ...transaction,
            displayDate: moment(transaction.effectiveAt),
          }))
          .sort((t1, t2) => (t1.displayDate > t2.displayDate ? 1 : -1))
          .map(
            (
              { id, amount, trigger, effectiveAt, paymentMethod, displayDate },
              index,
            ) => {
              let description;

              switch (trigger) {
                case 'autopay':
                case 'autopay_retry':
                  description = 'AutoPay';
                  break;
                case 'manual':
                case 'customer_support':
                  description = 'Payment';
                  break;
                case 'charge':
                  description =
                    index === 0
                      ? 'Credit Protection: start'
                      : 'Credit Protection: auto-renew';
                  break;
              }

              return (
                <ListItem disablePadding key={id} data-testid="transactionRow">
                  <ListItemText sx={{ margin: theme.spacing(0.75, 0) }}>
                    <Typography display="flex">
                      {description + ' '}
                      <TransactionAmount amountCents={amount} />
                    </Typography>
                    <Typography variant="footnote">
                      {displayDate.format('MM/DD/YY')}
                      {trigger === 'charge' && (
                        <>
                          <Box
                            component="span"
                            sx={{
                              margin: theme.spacing(0, 0.75),
                            }}
                          >
                            |
                          </Box>
                          <PopupDialog
                            dialogText={
                              <TransactionInvoice
                                accountNumber={
                                  chargeAccountApplication.metro2Id
                                }
                                description={description as InvoiceDescription}
                                amount={amount}
                                effectiveAt={effectiveAt}
                              />
                            }
                            variant="footnote"
                            sx={{ verticalAlign: 'top' }}
                          >
                            Invoice
                          </PopupDialog>
                        </>
                      )}
                      {paymentMethod &&
                        [
                          'manual',
                          'customer_support',
                          'autopay',
                          'autopay_retry',
                        ].includes(trigger) && (
                          <Box component="span">
                            <Box
                              component="span"
                              sx={{
                                margin: theme.spacing(0, 0.75),
                              }}
                            >
                              |
                            </Box>
                            <PaymentMethodLabel
                              hideIcons
                              paymentMethod={paymentMethod}
                            />
                          </Box>
                        )}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            },
          )}
      </List>
    </Card>
  );
};

export default ChargeAccountTransactionsCard;
