import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Grid,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import PasswordInputWithStrength from 'components/PasswordInputWithStrength';
import TextField from 'components/shared/TextField';
import VerificationCodeInput from 'components/VerificationCodeInput';
import { theme } from 'context/ThemeProvider';
import { normalizeEmail } from 'helpers/utils';
import { KovoError } from 'libs/KovoError';
import { AuthErrorCode } from 'libs/signupHelpers/types';

interface FormValues {
  confirmationCode: string;
  newPassword: string;
  confirmPassword: string;
}

export interface LocationState {
  email: string;
}

const ResetPasswordForm: React.FC = () => {
  const { search } = useLocation<LocationState>();

  const query = new URLSearchParams(search);
  const emailQueryParam = query.get('email');
  const email = emailQueryParam ? normalizeEmail(emailQueryParam) : undefined;
  const verificationCode = query.get('verificationCode');

  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const { errors, touched, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        confirmationCode: verificationCode ?? '',
        newPassword: '',
        confirmPassword: '',
      },
      validationSchema: yup.object().shape({
        confirmationCode: yup
          .string()
          .required('Verification code is required'),
        newPassword: yup.string().required('New password is required'),
        confirmPassword: yup
          .string()
          .required('Passwords must match')
          .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
      }),
      onSubmit,
      validateOnBlur: false,
    });

  const resetPassword = async ({
    confirmationCode,
    newPassword,
  }: FormValues) => {
    try {
      if (!email) {
        throw new Error('Email is required when resetting password');
      }

      await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      let resetPasswordError = new KovoError('Error resetting password')
        .setError(error)
        .addMetadata({ email });

      if (error instanceof Error) {
        switch (error.name) {
          case 'InvalidPasswordException':
            resetPasswordError
              .setDisplayMessage(error.message)
              .setLogLevel('warn')
              .setCode(AuthErrorCode.INVALID_PASSWORD);
            break;
          case 'CodeMismatchException':
            resetPasswordError
              .setDisplayMessage(error.message)
              .setLogLevel('warn')
              .setCode(AuthErrorCode.INVALID_CONFIRMATION_CODE);
            break;
          case 'LimitExceededException':
            resetPasswordError
              .setDisplayMessage(error.message)
              .setLogLevel('warn')
              .setCode(AuthErrorCode.LIMIT_EXCEEDED);
            break;
        }
      }

      throw resetPasswordError;
    }
  };

  const {
    mutate,
    isSuccess,
    isLoading,
    error: resetPasswordError,
  } = useMutation<void, KovoError, FormValues>(resetPassword);

  if (isSuccess) {
    return (
      <>
        <Typography variant="h1" marginBottom={theme.spacing(2)}>
          Your password was successfully reset.
        </Typography>

        <Typography marginBottom={theme.spacing(2)}>
          Log in with your new password.
        </Typography>

        <Button variant="contained" component={Link} to="/login" fullWidth>
          Log in
        </Button>
      </>
    );
  }

  return (
    <>
      {resetPasswordError && (
        <Alert
          severity="error"
          sx={{ textAlign: 'left', marginBottom: theme.spacing(5) }}
        >
          {resetPasswordError.displayMessage}
        </Alert>
      )}

      <Typography
        variant="h1"
        sx={{
          marginBottom: theme.spacing(2),
        }}
      >
        Reset Your Password
      </Typography>

      <Typography marginBottom={theme.spacing(3)}>
        We sent a verification code to this email if it is associated with an
        account in our system.
      </Typography>
      <Typography>
        Email: <strong>{email}</strong>
      </Typography>
      <Typography variant="footnote" marginBottom={theme.spacing(3)}>
        Need to correct your email?{' '}
        <MuiLink component={Link} to="/forgot-password">
          Go back
        </MuiLink>
        .
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} marginBottom={theme.spacing(3)}>
          <Grid item xs={12}>
            <VerificationCodeInput
              name="confirmationCode"
              label="Verification code"
              autoComplete="one-time-code"
              value={values.confirmationCode}
              error={!!(touched.confirmationCode && errors.confirmationCode)}
              errorText={errors.confirmationCode}
              onChange={handleChange}
              onBlur={handleBlur}
              onResendCode={async () => {
                if (!email) {
                  throw new Error('Email is required when resending code');
                }

                await Auth.forgotPassword(email);
              }}
              recentlySentLabel="Code sent. Check your email."
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <PasswordInputWithStrength
              autoComplete="new-password"
              name="newPassword"
              label="New password"
              value={values.newPassword}
              error={!!(touched.newPassword && errors.newPassword)}
              errorText={errors.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm password"
              autoComplete="new-password"
              value={values.confirmPassword}
              error={!!(touched.confirmPassword && errors.confirmPassword)}
              errorText={errors.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Grid>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          fullWidth
        >
          Reset Password
        </LoadingButton>
      </form>

      <Typography
        variant="footnote"
        marginTop={theme.spacing(3)}
        marginBottom={theme.spacing(1)}
      >
        Don’t have an account?{' '}
        <MuiLink component={Link} to="/signup">
          Sign Up
        </MuiLink>
        .
      </Typography>

      <Typography variant="footnote">
        Don't need to reset your password?{' '}
        <MuiLink component={Link} to="/login">
          Log in
        </MuiLink>
        .
      </Typography>
    </>
  );
};

export default ResetPasswordForm;
